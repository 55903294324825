<template>
  <b-overlay :show="loading">
    <b-card>
      <h3>Resumen</h3>
      <hr>
      <b-card-body>
        <b-row>
          <b-col cols="4">
            <div class="mt-md-0 mt-2">
              <h4>
                Datos de la Venta
              </h4>
              <table class="mt-2 mt-xl-0 w-100 text-left">
                <tr>
                  <th class="pb-50">

                    <span>Ficha: </span>
                    <span class="font-weight-bold">{{ value.ficha ? value.ficha.num_ficha:'-' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">
                    <span>Tipo de Venta: </span>
                    <span class="font-weight-bold">{{ value.categoria ? value.categoria.nombre_categoria:'-' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Estado Rectificación: </span>
                    <span class="font-weight-bold">{{ value.rectificacion ? value.rectificacion.descripcion_estado_edutecno : '-' }}</span>
                  </th>
                </tr>
              </table>
              <div />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="mt-md-0 mt-2">
              <h4>
                Datos de la OTIC
              </h4>
              <table class="mt-2 mt-xl-0 w-100 text-left">
                <tr>
                  <th class="pb-50">

                    <span>Otic: </span>
                    <span class="font-weight-bold">{{ value.otic ? value.otic.nombre_corto_otic: '-' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Código Sence: </span>
                    <span class="font-weight-bold">{{ value.ordenCompra ? value.ordenCompra.codigo_sence : '' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>N° Orden Compra: </span>
                    <span class="font-weight-bold">{{ value.ordenCompra ? value.ordenCompra.num_orden_compra : '' }}</span>
                  </th>
                </tr>
              </table>
              <div />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="mt-md-0 mt-2">
              <h4>
                Lugar de Ejecución
              </h4>
              <table class="mt-2 mt-xl-0 w-100 text-left">
                <tr>
                  <th class="pb-50">

                    <span>Sede: </span>
                    <span
                      v-if="value.sede"
                      class="font-weight-bold"
                    >{{ value.sede.nombre_sede }}</span>
                    <span
                      v-else
                      class="font-weight-bold"
                    >No Informado</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Sala: </span>
                    <span
                      v-if="value.sala"
                      class="font-weight-bold"
                    >{{ value.sala.nombre }}</span>
                    <span
                      v-else
                      class="font-weight-bold"
                    >No Informado</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Dirección o Lugar Ejecución: </span>
                    <span
                      v-if="value.ficha"
                      class="font-weight-bold"
                    >{{ value.ficha.lugar_ejecucion }}</span>
                    <span
                      v-else
                      class="font-weight-bold"
                    >No Informado</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Días: </span>
                    <br>
                    <b-form-group
                      v-for="(section, i) in selectDay"
                      label-for="input-customer-name"
                      class="custom-control-inline"
                    >
                      <span :class="[section.checked ? 'text-success' : 'font-weight-bold']">
                        {{ section.text }}
                      </span>
                    </b-form-group>
                  </th>
                </tr>
              </table>
              <div />
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col cols="4">
            <div class="mt-md-0 mt-2">
              <h4>
                Datos de la Empresa
              </h4>
              <table class="mt-2 mt-xl-0 w-100 text-left">
                <tr>
                  <th class="pb-50">

                    <span>Holding: </span>
                    <span class="font-weight-bold">{{ value.holding ? value.holding.nombre_holding : '' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Empresa: </span>
                    <span class="font-weight-bold">{{ value.empresa ? value.empresa.razon_social_empresa:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Dirección Empresa: </span>
                    <span class="font-weight-bold">{{ value.empresa ?value.empresa.direccion_empresa:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Nombre Contacto: </span>
                    <span class="font-weight-bold">{{ value.ficha ? value.ficha.nombre_contacto: '' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Teléfono Contacto: </span>
                    <span class="font-weight-bold">{{ value.ficha ? value.ficha.telefono_contacto:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">
                    <span>Dirección envío Diplomas: </span>
                    <span class="font-weight-bold">{{ value.ficha ? value.ficha.direccion_diplomas:'' }}</span>
                  </th>
                </tr>
              </table>
              <div />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="mt-md-0 mt-2">
              <h4>
                Datos del Curso
              </h4>
              <table class="mt-2 mt-xl-0 w-100 text-left">
                <tr>
                  <th class="pb-50">

                    <span>Modalidad: </span>
                    <span class="font-weight-bold">{{ value.modalidad ? value.modalidad.nombre_modalidad:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Curso: </span>
                    <span class="font-weight-bold">{{ value.curso ? value.curso.nombre_curso:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Versión: </span>
                    <span class="font-weight-bold">{{ value.version ? value.version.descripcion_version:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Fecha Inicio: </span>
                    <span class="font-weight-bold">{{ value.ordenCompra ? value.ordenCompra.fecha_inicio:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Fecha Término: </span>
                    <span class="font-weight-bold">{{ value.ordenCompra ? value.ordenCompra.fecha_cierre:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Hora Inicio: </span>
                    <span class="font-weight-bold">{{ value.ordenCompra ? value.ordenCompra.hora_inicio:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Hora Término: </span>
                    <span class="font-weight-bold">{{ value.ordenCompra ? value.ordenCompra.hora_termino:'' }}</span>
                  </th>
                </tr>
              </table>
              <div />
            </div>
          </b-col>

          <b-col cols="4">
            <div class="mt-md-0 mt-2">
              <h4>
                Información Interna
              </h4>
              <table class="mt-2 mt-xl-0 w-100 text-left">
                <tr>
                  <th class="pb-50">

                    <span>Ejecutivo Comercial: </span>
                    <span class="font-weight-bold">{{ value.usuario ? value.usuario.nombre:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>N° Solicitud Tablet: </span>
                    <span
                      class="font-weight-bold"
                    >{{ value.ficha ? value.ficha.id_solicitud_tablet:'' }}</span>
                  </th>
                </tr>
                <tr>
                  <th class="pb-50">

                    <span>Observaciones: </span>
                    <span class="font-weight-bold">{{ value.ordenCompra ? value.ordenCompra.comentario_orden_compra:'' }}</span>
                  </th>
                </tr>
              </table>
              <div />
            </div>
          </b-col>
          <b-button
            v-if="validacionEnviar"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="enviarRectificacionRevision"
          >
            Enviar
          </b-button>
            <span  class="font-weight-bold">{{ enviarRectificacion ? '' : '' }}</span>
        </b-row>
      </b-card-body>
      <hr>
      <b-row>
        <b-col cols="12">
          <div class="mt-md-0 mt-2">
            <h4>
              Rectificaciones Alumnos
            </h4>
            <b-table
              id="my-table"
              ref="table"
              striped
              hover
              small
              :items="alumnos"
              responsive
              class="mb-0"
              show-empty
            >

              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
            </b-table>
            <div />
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col cols="12">
          <div class="mt-md-0 mt-2">
            <h4>
              Lugar de Ejecución
            </h4>
            <b-table
              id="my-table"
              ref="table"
              striped
              hover
              small
              :fields="fields"
              :items="docRectificacion"
              responsive
              class="mb-0"
              show-empty
            >
              <template #cell(nombre_documento)="data">
                <b-link
                  class="font-weight-bold"
                  :href="`${data.item.ruta_documento}`"
                  target="_blank"
                >
                  {{ data.item.nombre_documento }}</b-link>
              </template>
              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
            </b-table>
            <div />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import { BButton, BFormCheckboxGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormCheckboxGroup,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      idRectificacion: 0,
      diasOcupados: [],
      validacionEnviar: false,
      data: {
        id_rectificacion: null,
      },
      fields: [
        { key: 'tipo_documento' },
        { key: 'nombre_documento', label: 'Documento' },
      ],
      days: [
        {
          id: 1, text: 'Lun', checked: false, notEnabled: true,
        },
        {
          id: 2, text: 'Mar', checked: false, notEnabled: true,
        },
        {
          id: 3, text: 'Mie', checked: false, notEnabled: true,
        },
        {
          id: 4, text: 'Jue', checked: false, notEnabled: true,
        },
        {
          id: 5, text: 'Vie', checked: false, notEnabled: true,
        },
        {
          id: 6, text: 'Sab', checked: false, notEnabled: true,
        },
        {
          id: 7, text: 'Dom', checked: false, notEnabled: true,
        },
      ],
      selectDayArr: [
        1, 2,
      ],
    }
  },
  computed: {
    value() {
      return this.$store.state.listadoRectificaciones.listadoDetalle
    },
    alumnos() {
      return this.$store.state.listadoRectificaciones.listadoDetalleAlumnos
    },
    enviarRectificacion() {
      let valor=null
      this.validacionEnviar=false
      valor = this.$store.state.listadoRectificaciones.estado
      if (valor == 10 || valor == 30 || valor == 50) {
        return this.validacionEnviar = true
      }
      return false
    },
    docRectificacion() {
      return this.$store.state.listadoRectificaciones.docRectificacion
    },
    selectDay() {
      this.diasOcupados = this.$store.state.listadoRectificaciones.listadoDetalle.fichaDia
      if (this.diasOcupados != null) {
        this.diasOcupados.forEach(valueDay => {
          this.days.forEach(valueSalect => {
            if (valueSalect.id == valueDay) {
              valueSalect.checked = true
            }
          })
        })
      }
      return this.days
    },

    loading() {
      return this.$store.state.listadoRectificaciones.loading
    },
  },

  mounted() {
    this.idRectificacion = this.$route.params.id
    this.$store.dispatch('listadoRectificaciones/getDetalleRectificacion', this.idRectificacion)
  },
  methods: {
    enviarRectificacionRevision() {
      this.$bvModal
        .msgBoxConfirm('¿Estas seguro que deseas enviar esta solicitud?', {
          title: 'Rectificación',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.data.id_rectificacion = this.idRectificacion
            this.$store.dispatch('listadoRectificaciones/updateEstadoRectificacion', this.data)
            this.$bvToast.toast('Rectificación Enviada', {
              title: 'Rectificacion',
              variant: 'success',
              solid: true,
            })
            this.$router.push({ name: 'rectificaciones'})
          }
        })
    },
  },
}

</script>

<style scoped>

</style>
